import React, { useEffect, useRef } from "react";
import cn from "classnames";
import Button from "@/components/kit/button";
import Carousel from "@/components/carousel";
import { useIntersection } from "react-use";
import Img from "gatsby-image";
import ArrowDownIcon from "@/assets/icons/arrow-down.inline.svg";
import * as css from "./list-item.modules.scss";
import { useI18n } from "@/plugins/i18n";

const ListItem = ({ data, className, setActive, ...rest }) => {
  const intersectionRef = useRef(null);
  const { t } = useI18n();

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  });

  useEffect(() => {
    if (intersection && intersection.isIntersecting) setActive(data.title);
  }, [intersection]);

  return (
    <div
      className={cn(className, "desktop:flex")}
      {...rest}
      ref={intersectionRef}
    >
      <div className="tablet:w-7/24 desktop:w-1/4">
        <div className={cn(css.sticky, "desktop:sticky")}>
          <div className={cn(css.year, "text-center tablet:text-left")}>
            {data.title}
          </div>

          {data.archiveSiteUrl && (
            <Button
              className="mt-4 w-full desktop:mt-6 desktop1280:mt-7 desktop1440:mt-8"
              is="a"
              href={data.archiveSiteUrl}
              variant="outlined"
              renderIcon={() => (
                <ArrowDownIcon className="transform -rotate-135" />
              )}
            >
              {t("archive.gotoSite")}
            </Button>
          )}

          {data.programFile && (
            <Button
              className="mt-4 w-full desktop:mt-6 desktop1280:mt-7 desktop1440:mt-8"
              is="a"
              download
              href={data.programFile.file.url}
              variant="outlined"
              renderIcon={() => <ArrowDownIcon />}
            >
              {data.programFile.title}
            </Button>
          )}
        </div>
      </div>

      <div className="desktop:w-1/24" />

      <div className={"mt-10 tablet:mt-8 desktop:flex-1 desktop:mt-0"}>
        {Array.isArray(data?.carousel) && (
          <Carousel className={cn(css.carousel, "mb-12 tablet:mb-13 ")}>
            {data.carousel.map((item, i) => (
              <Img key={i} {...item} />
            ))}
          </Carousel>
        )}

        <div className={"tablet:flex"}>
          <div
            className={cn(css.plain, "tablet:flex-1")}
            dangerouslySetInnerHTML={{
              __html: data.text?.childMarkdownRemark?.html ?? "",
            }}
          />

          <div className={"tablet:w-1/12 tablet:flex-shrink-0"} />

          <div className={"mt-10 tablet:mt-0 tablet:flex-1"}>
            {data.quotes?.map((quote) => (
              <div
                key={quote.id}
                className={"border-t-2 border-solid pt-4 pb-10"}
              >
                <h4 className="uppercase">{quote.subject.subject}</h4>
                <div
                  className={cn(
                    css.quote,
                    "mt-2 tablet:mt-5 desktop:mt-6 desktop1280:mt-7"
                  )}
                >
                  «
                </div>

                <div
                  className={"mt-7 text-normal desktop1280:mt-8"}
                  dangerouslySetInnerHTML={{
                    __html: quote.text?.childMarkdownRemark?.html ?? "",
                  }}
                />

                <div
                  className={cn(
                    css.quoteSource,
                    "uppercase mt-5 w-1/2 ml-auto text-normal " +
                      "tablet:w-3/7 tablet:mr-10 " +
                      "desktop:mt-6 desktop:mr-0 desktop:w-5/8"
                  )}
                  dangerouslySetInnerHTML={{
                    __html: quote.source?.childMarkdownRemark?.html ?? "",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
