import React, { MouseEvent, useEffect, useState } from "react";
import cn from "classnames";
import NukaCarousel from "nuka-carousel";
import ArrowRight from "@/assets/icons/arrow-right.inline.svg";
import useMedia from "@/utils/useMedia";
import * as css from "./pagination.modules.scss";
import { useCallback } from "react";

const Pagination = ({ items, active, className, onClick }) => {
  const config = useMedia(
    ["screen and (min-width: 1440px)", "screen and (min-width: 1280px)"],
    [{ slidesOnScreen: 24 }, { slidesOnScreen: 20 }],
    { slidesOnScreen: 16 }
  );

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    let index = items.findIndex((i) => i === active);

    if (config && index >= 0) {
      if (index >= items.length - config.slidesOnScreen)
        index = items.length - config.slidesOnScreen;

      setActiveIndex(index);
    }
  }, [active, config]);

  const handleClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const index = e.currentTarget.dataset.index;
    typeof onClick === "function" && onClick(index);
  }, []);

  if (!config) {
    return null;
  }

  return (
    <div className={cn(css.pagination, className, "relative")}>
      <NukaCarousel
        afterSlide={setActiveIndex}
        slideIndex={activeIndex}
        slideWidth={1 / config.slidesOnScreen}
        scrollMode={"remainder"}
        className={css.slider}
        autoplay={false}
        speed={300}
        edgeEasing={"easeSineOut"}
        renderBottomCenterControls={() => null}
        renderCenterLeftControls={({ previousSlide, currentSlide }) => (
          <button
            className={css.arrow}
            onClick={previousSlide}
            disabled={currentSlide === 0}
          >
            <ArrowRight className="transform rotate-180" />
          </button>
        )}
        renderCenterRightControls={({
          nextSlide,
          currentSlide,
          slideCount,
        }) => (
          <button
            className={css.arrow}
            onClick={nextSlide}
            disabled={currentSlide >= slideCount - config.slidesOnScreen}
          >
            <ArrowRight />
          </button>
        )}
      >
        {items.map((i) => (
          <div
            key={i}
            className={cn({ "font-bold": active === i }, "text-center")}
            data-index={i}
            onClick={handleClick}
          >
            {i.slice(-2)}
          </div>
        ))}
      </NukaCarousel>
    </div>
  );
};

export default Pagination;
