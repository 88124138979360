import React from "react";
import Archive from "@/components/pages/archive";
import { graphql } from "gatsby";
import SEOEn from "@/components/seo/index.en";
import SEORu from "@/components/seo/index.ru";

const ArchiveEn = (props) => {
  const { pageContext } = props;

  return (
    <>
      {pageContext.langKey === "en" && (
        <SEOEn
          title={props.data.layoutArchive.title}
          description={props.data.layoutArchive.ogDescription?.ogDescription}
          ogImage={props.data.layoutArchive.ogImage}
        />
      )}
      {pageContext.langKey === "ru" && (
        <SEORu
          title={props.data.layoutArchive.title}
          description={props.data.layoutArchive.ogDescription?.ogDescription}
          ogImage={props.data.layoutArchive.ogImage}
        />
      )}

      <Archive {...props} />
    </>
  );
};

export const pageQuery = graphql`
  query($locale: String!) {
    layoutArchive: contentfulLayoutArchive(node_locale: { eq: $locale }) {
      ...ArchivePageFragment
    }
  }
`;

export default ArchiveEn;
