import React, { useState } from "react";
import cn from "classnames";
import PageTitle from "@/components/kit/page-title";
import Pagination from "./pagination";
import ListItem from "./list-item";
import ClientOnly from "@/utils/ClientOnly";
import * as styleConstants from "@/constants/style";
import { graphql } from "gatsby";
import { useStateValue } from "@/store";
import { Portal } from "react-portal";
import { CSSTransition } from "react-transition-group";
import * as css from "./archive.modules.scss";
import { useI18n } from "@/plugins/i18n";

const Y_PADDING_TOP = -60;

const Archive = ({ data }) => {
  const { layoutArchive } = data;
  const [activeYear, set] = useState(layoutArchive.blocks[0].title);

  const toItem = (item) => {
    const element = document.querySelector(`#archive-${item}`);

    if (!element) return;

    const y =
      element.getBoundingClientRect().top + window.pageYOffset + Y_PADDING_TOP;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const [{ footerIsVisible }] = useStateValue();
  const { t } = useI18n();
  const CHOOSE_A_YEAR_TEXT = t("choose_a_year");

  return (
    <div className="mb-28 desktop:pt-10 desktop1280:pt-12 desktop1440:pt-14">
      <PageTitle>{layoutArchive.title}</PageTitle>

      <div className={cn(styleConstants.container, "py-6 desktop:py-7")}>
        {layoutArchive.blocks.map((block, i) => (
          <ListItem
            setActive={set}
            data={block}
            key={i}
            className={{
              "mt-8 tablet:mt-12 desktop:mt-27 desktop1280:mt-29 desktop1440:mt-34": i,
            }}
            id={`archive-${block.title}`}
          />
        ))}
      </div>

      <Portal>
        <CSSTransition
          in
          unmountOnExit
          appear
          timeout={1000}
          classNames="archive-fixed-nav"
        >
          <div
            className={cn(
              css.nav,
              { "is-show": !footerIsVisible },
              "fixed bottom-0 left-0 right-0 pb-3 desktop:pb-0 z-20"
            )}
          >
            <div
              className={cn(
                styleConstants.container,
                "h-full flex items-center"
              )}
            >
              <div className={"text-normal desktop:hidden font-bold"}>
                {activeYear}
              </div>

              <label
                className={
                  "relative uppercase text-normal ml-auto desktop:hidden h-full items-center flex flex-1 justify-end"
                }
              >
                {CHOOSE_A_YEAR_TEXT}
                <select
                  className={
                    "absolute top-0 left-0 right-0 bottom-0 opacity-0 "
                  }
                  style={{ fontSize: "16px" }}
                  onChange={(event) => toItem(event.target.value)}
                >
                  {layoutArchive.blocks.map((block, i) => (
                    <option key={i} value={block.title}>
                      {block.title}
                    </option>
                  ))}
                </select>
              </label>

              <ClientOnly>
                <Pagination
                  items={layoutArchive.blocks.map((block) => block.title)}
                  active={activeYear}
                  className={"hidden desktop:block flex-1"}
                  onClick={toItem}
                />
              </ClientOnly>
            </div>
          </div>
        </CSSTransition>
      </Portal>
    </div>
  );
};

export default Archive;

export const archivePageFragment = graphql`
  fragment ArchivePageFragment on ContentfulLayoutArchive {
    title
    blocks {
      title
      text {
        text
        childMarkdownRemark {
          html
        }
      }
      quotes {
        id
        subject {
          subject
        }
        text {
          text
          childMarkdownRemark {
            html
          }
        }
        source {
          source
          childMarkdownRemark {
            html
          }
        }
      }
      archiveSiteUrl
      programFile {
        title
        file {
          url
        }
      }
      carousel {
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    ogDescription {
      ogDescription
    }
    ogImage {
      file {
        url
        details {
          image {
            height
            width
          }
        }
      }
    }
  }
`;
